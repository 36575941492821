<template>
  <div class="flex flex-row items-center justify-center gap-2">
    <Button
      v-if="!inputOpened"
      theme="outline"
      :size="size"
      @click="openInputField"
    >
      <font-awesome-icon icon="fa-light fa-coins" />
      Send Tip
    </Button>
    <FormInput
      v-else
      v-model="tokens"
      type="number"
      placeholder="Tokens.."
      class="animate-fade"
      :ui="inputStyles"
    >
      <template #leading>
        <font-awesome-icon icon="fa-light fa-coins" color="gray" />
      </template>
      <template #trailing>
        <Button size="xs" @click="sendTip">Send</Button>
      </template>
    </FormInput>
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const tokenPurchaseModalOpen = inject("tokenPurchaseModalOpen")
  const loginModalOpen = inject("loginModalOpen")

  const props = defineProps({
    creator: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    showInput: {
      type: Boolean,
      default: false,
    },
    tokenCount: {
      type: Number,
      default: null,
    },
  })

  const toast = useToast()
  const userStore = useUserStore()
  const config = useRuntimeConfig()
  const inputOpened = ref(false)

  watch(
    () => props.showInput,
    () => {
      inputOpened.value = props.showInput
    },
  )

  const emit = defineEmits(["post-tipped"])

  const tokens = ref(null)

  const openInputField = async () => {
    if (userStore.loggedIn) {
      if (!userStore.tokenBalance) {
        toast.add({ title: "You have no tokens to tip." })
        tokenPurchaseModalOpen.value = true
      } else {
        inputOpened.value = true
      }
    } else {
      loginModalOpen.value = true
    }
  }

  const sendTip = async () => {
    if (!tokens.value > 0) {
      inputOpened.value = false
      return false
    }

    try {
      await $api(
        `${config.public.API_URL}/api/posts/${props.post.id}/send_tip`,
        {
          method: "POST",
          body: {
            token_amount: tokens.value,
          },
        },
      )
      toast.add({
        title: "Tip sent!",
        description: `You sent ${tokens.value} tokens to @${props.creator.username}`,
      })
      emit("post-tipped", tokens.value)
      tokens.value = null
      inputOpened.value = false
    } catch (error) {
      tokens.value = null
      inputOpened.value = false
      const message = error.response._data.tips.join(", ")
      toast.add({ title: message, color: "red" })
    }
  }

  const inputStyles = {
    wrapper: "relative text-sm",
    rounded: "rounded-full",
    padding: "lg",
    variant: {
      none:
        "font-semibold ring-1 ring-[#E4E4E4] " +
        "focus:ring-1 focus:ring-[#f26998] " +
        "dark:ring-[#384247] dark:bg-[#1b2022] " +
        "dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]",
    },
    icon: {
      trailing: {
        wrapper: "absolute inset-y-0 end-0 flex items-center !px-1",
        pointer: "",
      },
    },
  }
</script>
