<!-- TODO: Calling all frontend wizards... Let's do this: https://build.washingtonpost.com/v0/design/uncategorized/pagination-dots -->

<template>
  <div
    v-if="theme == `micro`"
    class="w-full flex flex-row items-center justify-center gap-1"
  >
    <a
      @click.prevent="emitPrevious"
      href="#"
      class=""
      :class="{ 'text-gray-200 dark:text-gray-900': !props.previousPage }"
    >
      <font-awesome-icon
        icon="fa-solid fa-chevron-left"
        class="text-gray-400 pr-4"
      />
    </a>
    <a
      href="#"
      @click.prevent="goToPage(page)"
      v-for="page in paginationRange"
      :key="page"
      class=""
      :class="{ 'text-gray-100': page === props.currentPage }"
    >
      <font-awesome-icon icon="fa-solid fa-circle" class="text-xs" />
    </a>
    <a
      @click.prevent="emitNext"
      href="#"
      class=""
      :class="{ 'text-gray-100 dark:text-gray-900': !props.nextPage }"
    >
      <font-awesome-icon
        icon="fa-solid fa-chevron-right"
        class="text-gray-400 pl-4"
      />
    </a>
  </div>
</template>

<script setup>
  const props = defineProps({
    theme: {
      type: String, // 'full' | 'micro'
      required: false,
      default: "full",
    },
    currentPage: {
      type: Number,
      required: true,
    },
    nextPage: {
      type: String,
      required: false,
    },
    perPage: {
      type: Number,
      required: true,
    },
    previousPage: {
      type: String,
      required: false,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  })

  const emit = defineEmits(["next", "previous"])

  const paginationRange = computed(() => {
    if (props.totalPages > 6) {
      return 6
    } else {
      return props.totalPages
    }
  })

  const emitNext = () => {
    if (props.nextPage) {
      emit("next")
    }
  }

  const emitPrevious = () => {
    if (props.previousPage) {
      emit("previous")
    }
  }

  const goToPage = (number) => {
    emit("goToPage", number)
  }
</script>
