<template>
  <UModal
    @close="() => emit('close')"
    :closeOnOverlayClick="true"
    :closeOnEscape="true"
    :center="true"
    :overlay="false"
    :fullscreen="true"
    :ui="modalStyles"
  >
    <div
      v-touch:swipe="handleSwipe"
      class="flex flex-col justify-center items-center animate-fade"
    >
      <img
        v-if="asset.type === 'image'"
        :src="asset.signedUrl"
        alt=""
        class="object-contain h-[100dvh] w-auto"
      />

      <div
        v-else-if="asset.type === 'video'"
        class="h-screen w-auto flex justify-center"
      >
        <media-controller class="flex justify-center">
          <video
            slot="media"
            :src="asset.signedUrl"
            :poster="asset.signedThumbnailUrl"
            playsinline
            autoplay
          ></video>
          <media-control-bar>
            <media-play-button></media-play-button>
            <media-time-range></media-time-range>
            <media-mute-button></media-mute-button>
          </media-control-bar>
        </media-controller>
      </div>
    </div>

    <!-- Close Button -->
    <div class="fixed z-9 right-4 top-4 text-white">
      <font-awesome-icon
        icon="fa-light fa-circle-xmark"
        class="fa-fw text-2xl cursor-pointer"
        @click="() => emit('close')"
      />
    </div>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    asset: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits(["close"])

  const modalStyles = {
    inner: "fixed inset-0 overflow-y-unset",
    background: "bg-black dark:bg-black",
    container:
      "flex min-h-[100dvh] items-end sm:items-center justify-center text-center",
  }

  const handleSwipe = (event) => {
    switch (event) {
      case "top":
        break
      case "bottom":
        emit("close")
        break
      case "left":
        break
      case "right":
        break
      default:
        break
    }
  }
</script>

<style lang="scss">
  .overflow-y-unset {
    overflow-y: unset !important;
  }
</style>
