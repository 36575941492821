<template>
  <UModal v-model="postModel" :ui="modalStyles">
    <Modal class="dark:border-b-2 dark:border-black rounded-lg">
      <PostForm
        :mode="mode"
        :scheduled="scheduled"
        :post="post"
        @broadcast-feed-update="relayFeedUpdate"
        @post-updated="relayPostUpdated"
      />
    </Modal>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "create",
    },
    post: {
      type: Object,
      default: null,
    },
  })

  const emit = defineEmits(["broadcast-feed-update", "post-updated"])

  const dayjs = useDayjs()
  const postModel = ref(props.post, { deep: true })

  const scheduled = computed(() => {
    if (dayjs(props.post.postedAt) > dayjs()) {
      return props.post.postedAt
    }
  })

  const modalStyles = {
    container: "items-start md:items-center !w-screen",
    background: "dark:bg-[#1b2022]",
    overlay: {
      background: "dark:bg-[#1b2022] dark:bg-opacity-85",
    },
    fullscreen: "w-screen h-auto",
  }

  const relayFeedUpdate = (record) => {
    emit("broadcast-feed-update", record)
  }

  const relayPostUpdated = (record) => {
    emit("post-updated", record)
  }
</script>
