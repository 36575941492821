<template>
  <!-- Form -->
  <PostCommentForm
    v-if="canComment"
    :postId="props.postId"
    @comment-created="refreshComments"
  />
  <div v-else class="mt-4 text-center">
    <p class="text-sm text-[#595858] dark:text-[#555F63]">
      Subscribe to comment.
    </p>
  </div>

  <UDivider class="mt-2 py-2" />

  <div v-if="!commentsPending" class="animate-fade-down">
    <div
      v-if="!commentsPending && comments.items.length === 0"
      class="text-[#595858] dark:text-[#555F63] text-center"
    >
      No comments yet.
    </div>

    <!-- Feed -->
    <div
      id="comment-feed"
      v-if="!commentsPending"
      v-for="c in comments.items"
      class="flex flex-col gap-3"
    >
      <PostComment
        :comment="c"
        @comment-liked="handleCommentLiked"
        @comment-deleted="handleCommentDeleted"
        @comment-reply-added="handleCommentReply"
        @comment-unliked="handleCommentUnliked"
        @comment-complaint="handleCommentComplaint"
      />
    </div>

    <!-- View More -->
    <div
      v-if="
        !commentsPending &&
        comments.pagination.totalItems > 3 &&
        props.mode === 'compact'
      "
      class="italic text-center text-[#595858] dark:text-[#555F63] hover:text-pink-600 dark:hover:text-pink-500 mb-8 cursor-pointer"
      @click="handleViewMoreComments"
    >
      <NuxtLink
        :to="'/' + creator.username + '/posts/' + postId"
        class="text-xxs sm:text-sm whitespace-nowrap"
      >
        View more comments
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    postId: {
      type: String,
      required: true,
    },
    creator: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "compact", // 'full'
    },
  })

  const config = useRuntimeConfig()
  const userStore = useUserStore()

  const perPage = computed(() => {
    return props.mode === "full" ? 20 : 3
  })

  const subscription = ref(null)
  try {
    subscription.value = await $api(
      `${config.public.API_URL}/api/subscriptions/${props.creator.username}`,
      { method: "GET" },
    )
  } catch (error) {
    // NOOP: 404 is expected if no subscription exists
  }

  const canComment = computed(() => {
    return !!subscription.value
  })

  const {
    pending: commentsPending,
    data: comments,
    refresh: refreshComments,
  } = await useAuthFetch(
    config.public.API_URL +
      `/api/posts/${props.postId}/comments?per_page=${perPage.value}`,
    { method: "GET" },
  )

  const handleViewMoreComments = async () => {
    console.log("View more comments")
  }

  const handleCommentDeleted = () => {
    console.log("Comment deleted")
    refreshComments()
  }

  const handleCommentReply = () => {
    console.log("Comment replied")
    refreshComments()
  }

  const handleCommentLiked = () => {
    console.log("Comment liked")
  }

  const handleCommentUnliked = () => {
    console.log("Comment unliked")
  }

  const handleComplaint = () => {
    console.log("Comment reported")
  }
</script>
