<template>
  <swiper
    :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }"
    :zoom="true"
    :effect="'cards'"
    :centeredSlides="true"
    :navigation="showSlideNavigation"
    :pagination="paginationDynamic"
    :modules="modules"
    @slideChange="handleSlideChange"
  >
    <swiper-slide v-for="m in media" :key="m.id" @click="handleSlideClick(m)">
      <PostImageSlide v-if="m.type === 'image'" :image="m" />
      <PostVideoSlide v-if="m.type === 'video'" :video="m" />
    </swiper-slide>
  </swiper>
</template>

<script setup>
  import { PostPreviewModal } from "#components"
  import { Swiper, SwiperSlide } from "swiper/vue"

  import "swiper/css"
  import "swiper/css/navigation"
  import "swiper/css/effect-cards"
  import "swiper/css/pagination"
  import "swiper/css/scrollbar"
  import "swiper/css/zoom"

  import {
    Navigation,
    EffectCards,
    Pagination,
    Scrollbar,
    Zoom,
  } from "swiper/modules"

  const userStore = useUserStore()
  const route = useRoute()
  const modal = useModal()
  const isMobile = useMediaQuery("(max-width: 640px)")
  const modules = [Navigation, EffectCards, Pagination, Scrollbar, Zoom]
  const isOpen = ref(true)

  const showSlideNavigation = ref(isMobile.value ? false : true)

  const props = defineProps({
    media: {
      type: Array,
      required: true,
    },
  })

  const emit = defineEmits(["locked-media-clicked"])

  const paginationDynamic = {
    dynamicBullets: true,
  }

  const openPreviewModal = (asset) => {
    modal.open(PostPreviewModal, {
      asset: asset,

      /* Emit Listeners */
      onClose() {
        modal.close()
      },
    })
  }

  const handleSlideChange = (asset) => {
    // NOOP
  }

  const handleSlideClick = (asset) => {
    if (asset.locked && !asset.hasAccess) {
      emit("locked-media-clicked", asset)
    } else {
      if (userStore.loggedIn) {
        openPreviewModal(asset)
      } else {
        navigateTo({
          path: "/login",
          query: { redirectBackTo: route.fullPath },
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .swiper {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>

<style>
  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    text-align: center;
    line-height: 7px;
    font-size: 11px;
    color: #000;
    opacity: 1;
    background: #fff;
  }

  .swiper-pagination-bullet-active {
    @apply animate-fade;
    color: #fff;
    width: 10px;
    height: 5px;
  }
</style>
