<template>
  <div class="flex flex-row justify-between items-center">
    <MediaPackSummary
      :image-count="stats.media.imageCount"
      :video-count="stats.media.videoCount"
      :free="stats.price.isFree"
      :token-count="stats.price.tokenCount"
      class="text-[#595858] dark:text-[#555F63]"
    />
    <div
      v-if="releaseData?.scheduled"
      class="text-xs text-[#595858] dark:text-[#555F63]"
    >
      <!-- <font-awesome-icon icon="fa-solid fa-timer" class="fa-fw" />  -->
      <span>Scheduled:</span>
      {{ releaseData.date }}
    </div>
    <div
      v-else-if="override"
      class="text-xs text-[#595858] dark:text-[#555F63]"
    >
      <span>Posted:</span>
      {{ $dayjs(release).format("MMM D, YYYY") }}
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    stats: {
      type: Object,
      required: true,
    },
    release: {
      type: String,
      default: null,
    },
    override: {
      type: Boolean,
      default: false,
    },
  })

  const dayjs = useDayjs()

  const releaseData = computed(() => {
    if (props.override && dayjs(props.release) > dayjs()) {
      return {
        scheduled: true,
        date: dayjs(props.release).format("MMM D, YYYY"),
        time: dayjs(props.release).format("h:mm A"),
      }
    }
  })
</script>
