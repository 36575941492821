<template>
  <div :style="containerStyle" class="container">
    <div class="backdrop">
      <div v-if="!image.hasAccess && image.locked" class="constraint">
        <div
          class="absolute w-full h-full z-10 flex flex-col justify-center items-center gap-4"
        >
          <font-awesome-icon
            icon="fa-solid fa-lock-keyhole"
            class="fa-fw"
            size="lg"
          />
        </div>
        <BlurHashThumb
          :blur-hash="image.blurhash"
          class="h-[286px] md:h-[480px] w-[382px] md:w-[700px]"
        />
      </div>
      <div v-else class="contraint">
        <img :src="image.signedThumbnailUrl" alt="" class="fitme" />
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    image: {
      type: Object,
      required: true,
    },
  })

  const containerStyle = computed(() => {
    if (!props.image.locked || props.image.hasAccess) {
      return {
        backgroundImage: `url('${props.image.signedThumbnailUrl}')`,
      }
    } else {
      return {
        backgroundColor: "#000",
      }
    }
  })
</script>

<style lang="scss" scoped>
  .container {
    @apply w-full h-full;
  }

  .constraint {
    @apply w-full h-full relative;
  }

  .backdrop {
    @apply w-full h-full backdrop-blur-[10px] bg-[#fa3a88]/[0.4] flex justify-center;
  }

  .constraint {
    @apply h-[286px] md:h-[480px] md:w-[700px];
  }

  .fitme {
    @apply object-contain w-[382px] h-[286px] md:w-[700px] md:h-[480px];
  }
</style>
